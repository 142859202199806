@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400&display=swap');

.sm-landscape {
  @apply w-64 h-40 shadow-md !important;
}

.vsm-landscape{
  
  @apply w-32 h-40 shadow-md;
}

.lg-portrait{
  height: 22rem;
  @apply w-64 shadow-md
  
}

.display{
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
}

.display-heavy{
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
}

.line-wrapper{
  
}

.line{
  transition: 2s all cubic-bezier(0.23, 1, 0.32, 1);
  height: 900px;
  width: 43px;
  /* background: #feda4e; */
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
}

.grey-line{
  transition: 2s all cubic-bezier(0.23, 1, 0.32, 1);
  height: 900px;
  width: 3px;
  background: #efefec;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
}

.back{
  z-index: -10;
}

.very-back{
  z-index: -20;
}

.front {
  z-index: 0;
}

.yellow{
  background-color: #feda4e;
}

.center{
  transform: translate(-50%, -50%);
}

@keyframes spin-right {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(45deg);
  }
}

@keyframes spin-left {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(-45deg);
  }
}

.top-level{
  z-index: 10;
}

.top {
  box-shadow: 0 -25px 25px -25px rgb(183, 182, 182);
}